/**
 *  项目的配置文件
 */
export const apiUrl = 'https://www.luspstore.com/';//接口请求地址
export const chatUrl = 'wss://im.luspstore.com';//客服地址
export const mUrl = 'https://m.luspstore.com/'//移动端网页地址
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const title = '绿优甄选';//浏览器顶部title
export const gdKey = '';//高德web-js key
export const gdSecurityCode = ''//高德web-js 安全密钥
export const statShowDebug = false;//是否开启统计的调试

/** copyright *** slodon *** version-v5.0 *** date-2023-06-19 ***主版本v5.0**/

